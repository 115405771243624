@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

.mat-form-field-checkbox mat-checkbox.mat-mdc-checkbox {
  display: block;
  background-color: map-get(color.$monochrome, 200);
  border-radius: var.$border-radius-sm;

  .mdc-form-field {
    display: flex;
    align-items: flex-start;

    label[for^="mat-mdc-checkbox"] {
      display: flex;
      width: 100%;

      .mat-checkbox-inline-label {
        pointer-events: auto;
        white-space: pre-wrap;
        font-size: var.$font-size-sm;
        line-height: var.$line-height-base;
      }
    }
  }
}

// disabled styles
.mat-form-field-checkbox
mat-checkbox.mat-mdc-checkbox.mat-mdc-checkbox-disabled {
  cursor: not-allowed !important;
  pointer-events: unset;

  * {
    pointer-events: none !important;
  }

  .mat-checkbox-inline-label a {
    color: inherit;
  }
}
