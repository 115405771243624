@use "@ngbracket/ngx-layout/core/sass/layout-bp" as mq;

/**
generates spacing-helper-classes like
following this schema:

[p|m|nm][""|t|b|l|r|x|y]-[""|sm|md|lg|xl]-[0|4|8|12|16|20|24|32|48|64]

e.g
m-0 => margin: 0
pt-sm-12 => padding-top: 12px on devices where min-width is matching the sm-breakpoint
or
py-12 => padding-top: 12px and padding-bottom: 12px
or
nmy-12 => negative margin-top: 12px and negative margin-bottom: 12px
**/

$jbd-breakpoints: (
  "": map_get(map_get(mq.$breakpoints, xs), begin),
  "sm": map_get(map_get(mq.$breakpoints, sm), begin),
  "md": map_get(map_get(mq.$breakpoints, md), begin),
  "lg": map_get(map_get(mq.$breakpoints, lg), begin),
  "xl": map_get(map_get(mq.$breakpoints, xl), begin),
);

$sides: (
  "": "",
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
  "x": "",
  "y": "",
);

$spaces: 0, 4, 8, 12, 16, 20, 24, 32, 48, 64;

@mixin generateSpacer($selector, $property, $space, $sideName) {
  .m#{$selector} {
    @if $sideName == "y" {
      margin-block: #{$space}px !important;
    } @else if $sideName == "x" {
      margin-inline: #{$space}px !important;
    } @else {
      margin#{$property}: #{$space}px !important;
    }
  }

  .p#{$selector} {
    @if $sideName == "y" {
      padding-block: #{$space}px !important;
    } @else if $sideName == "x" {
      padding-inline: #{$space}px !important;
    } @else {
      padding#{$property}: #{$space}px !important;
    }
  }

  @if $space > 0 {
    .nm#{$selector} {
      @if $sideName == "y" {
        margin-block: -#{$space}px !important;
      } @else if $sideName == "x" {
        margin-inline: -#{$space}px !important;
      } @else {
        margin#{$property}: -#{$space}px !important;
      }
    }
  }
}

@mixin generateAutoSpacer($property, $sideName, $breakName, $breakValue) {
  @if $breakName != "" {
    @media (min-width: $breakValue) {
      .m#{#{$sideName}-#{$breakName}-auto} {
        @if $sideName == "y" {
          margin-block: auto !important;
        } @else if $sideName == "x" {
          margin-inline: auto !important;
        } @else {
          margin#{$property}: auto !important;
        }
      }
    }
  } @else {
    .m#{#{$sideName}-auto} {
      @if $sideName == "y" {
        margin-block: auto !important;
      } @else if $sideName == "x" {
        margin-inline: auto !important;
      } @else {
        margin#{$property}: auto !important;
      }
    }
  }
}

@each $breakName, $breakValue in $jbd-breakpoints {
  @each $sideName, $sideValue in $sides {
    $property: if($sideName == "", "", -#{$sideValue});
    $selector: if(
      $breakName != "",
      #{$sideName}-#{$breakName}-auto,
      #{$sideName}-auto
    );

    @include generateAutoSpacer($property, $sideName, $breakName, $breakValue);

    @each $space in $spaces {
      $selector: if(
        $breakName != "",
        #{$sideName}-#{$breakName}-#{$space},
        #{$sideName}-#{$space}
      );

      @if $breakName != "" {
        @media (min-width: $breakValue) {
          @include generateSpacer($selector, $property, $space, $sideName);
        }
      } @else {
        @include generateSpacer($selector, $property, $space, $sideName);
      }
    }
  }
}
