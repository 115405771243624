@use "@ngbracket/ngx-layout/core/sass/layout-bp" as mq;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/utils/size" as *;
@use "core/src/stylesheets/utils/scroll-hints" as *;
@use "variables" as *;

jbd-ui-form-select {
  // suffix icon
  .mat-mdc-select-arrow-wrapper {
    opacity: 0;
    @include size(0);
  }

  .mat-mdc-form-field-icon-suffix {
    align-self: flex-start;
    margin-top: 8px;

    .mat-select-custom-arrow svg {
      transform: scale(0.85);
      transition: transform 0.2s ease;
    }
  }

  .mat-mdc-select-placeholder {
    font-weight: $form-field-placeholder-font-weight;
    color: $form-field-placeholder-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

jbd-ui-form-select .mat-select-list-is-open {
  mat-form-field.mat-mdc-form-field
    .mat-mdc-form-field-flex
    .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open {
  @include scroll-hints(map-get(color.$monochrome, "white"), "vertical");
  border: $form-field-border-width solid $form-field-border-color--focused;
  border-top-width: 0;
  box-shadow: none;
  padding-block: 0;

  // option group
  .mat-mdc-optgroup .mat-mdc-optgroup-label .mdc-list-item__primary-text {
    --mdc-list-list-item-label-text-weight: #{$form-field-optgroup-label-font-weight};
    --mdc-list-list-item-label-text-size: #{$form-field-optgroup-label-font-size};
    color: $form-field-optgroup-label-color;
  }

  // option
  .mat-mdc-option {
    &:hover:not(.mdc-list-item--disabled) {
      background-color: map-get(color.$monochrome, 200);
    }

    &.mat-mdc-option-active {
      background-color: map-get(color.$monochrome, 200);

      &:hover {
        background-color: map-get(color.$monochrome, 300);
      }
    }

    .mdc-list-item__primary-text {
      font-size: $form-field-list-item-font-size;
      font-weight: $form-field-list-item-font-weight;
      color: $form-field-color;
    }

    // option disabled
    &.mdc-list-item--disabled {
      cursor: not-allowed;
      opacity: 0.4;

      * {
        pointer-events: none;
      }
    }
  }
}

// rotate chevron from icon button
jbd-ui-form-select .mat-select-list-is-open {
  .mat-mdc-form-field-icon-suffix .mat-select-custom-arrow svg {
    transform: scale(0.85) rotate(-180deg);
  }
}
