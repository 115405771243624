// mat-form-fields
.form-group {
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding-inline: 0;
  }

  .mat-mdc-form-field-bottom-align::before {
    content: unset;
  }
}
