jbd-app-campaign-print {
  .bg-monochrome-200 {
    background-color: transparent !important;
  }

  h6.mb-16 {
    margin-bottom: 8px !important;
  }

  .jbd-preview-card {
    min-height: unset !important;

    table.jbd-form tbody {
      td.period {
        width: auto !important;

        span.font-weight-semibold.mb-12 {
          display: none !important;
        }
      }

      td.retail-price {
        width: 100px !important;
        text-align: right !important;
      }
    }

    aside.jbd-preview-card-thumbnail {
      display: none !important;
    }
  }
}
