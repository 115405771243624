@use "base/colors" as customer-colors;

.mat-form-field-weekpicker-datepicker-widget {
  // header controls
  .mat-calendar-header .mat-calendar-controls button {
    &.mat-calendar-period-button {
      .mat-calendar-arrow {
        fill: map-get(customer-colors.$primary, 800);
      }
    }

    &.mat-calendar-previous-button:not([disabled]),
    &.mat-calendar-next-button:not([disabled]) {
      color: map-get(customer-colors.$primary, 800);
    }
  }

  // highlighted week focus
  &.highlight-start
    mat-month-view
    .mat-calendar-body-cell-container:first-of-type:not(
      .mat-calendar-body-label
    ),
  &.highlight-end
    mat-month-view
    .mat-calendar-body-cell-container[data-mat-col="6"]:not(
      .mat-calendar-body-label
    ) {
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled)
      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      background-color: map-get(customer-colors.$primary, 200);
      color: map-get(customer-colors.$primary, 700);
      border-radius: 50%;
    }
  }
}

// use picker with keyboard
.cdk-keyboard-focused,
.cdk-program-focused {
  .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: map-get(customer-colors.$primary, 200);
  }
}
