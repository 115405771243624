@use "@ngbracket/ngx-layout/core/sass/layout-bp" as mq;

$jbd-container-max-widths: (
  sm: 500px,
  md: 800px,
  lg: 1200px,
  xl: 1600px,
);

@mixin make-container() {
  width: 100%;
  margin-inline: auto;
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-fluid,
.fixed-width-container-sm,
.fixed-width-container-md,
.fixed-width-container-lg,
.fixed-width-container-xl {
  @include make-container();
}

.container,
.container-sm,
.fixed-width-container-sm {
  @include mq.layout-bp(gt-xs) {
    max-width: map-get($jbd-container-max-widths, sm);
  }
}

.container,
.container-sm,
.container-md,
.fixed-width-container-md {
  @include mq.layout-bp(gt-sm) {
    max-width: map-get($jbd-container-max-widths, md);
  }
}

.container,
.container-sm,
.container-md,
.container-lg,
.fixed-width-container-lg {
  @include mq.layout-bp(gt-md) {
    max-width: map-get($jbd-container-max-widths, lg);
  }
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.fixed-width-container-xl {
  @include mq.layout-bp(gt-lg) {
    max-width: map-get($jbd-container-max-widths, xl);
  }
}
