@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

$form-field-padding-x: 12px;
$form-field-padding-y: 12px;

$form-field-border-color: map-get(color.$monochrome, 400);
$form-field-border-width: 1px;
$form-field-border-radius: var.$border-radius-sm;
$form-field-background-color: map-get(color.$monochrome, "white");
$form-field-color: map-get(color.$monochrome, 900);

$form-field-border-color--focused: map-get(color.$monochrome, 600);

$form-field-background-color--disabled: map-get(color.$monochrome, 300);
$form-field-color--disabled: map-get(color.$monochrome, 600);

$form-field-placeholder-color: map-get(color.$monochrome, 500);
$form-field-placeholder-font-weight: var.$font-weight-semibold;
$form-field-placeholder-color--disabled: map-get(color.$monochrome, 400);

$form-field-optgroup-label-font-weight: var.$font-weight-semibold;
$form-field-optgroup-label-font-size: var.$font-size-sm;
$form-field-optgroup-label-color: map-get(color.$monochrome, 600);

$form-field-list-item-font-size: var.$font-size-base;
$form-field-list-item-font-weight: var.$font-weight-semibold;

// weekpicker
$form-field-weekpicker-limited-width: 280px;

// uploader
$form-field-file-dropzone-height: 120px;
