@mixin scroll-hints($bg-color, $direction) {
  @if $direction == "vertical" {
    background:
      /* Shadow covers */ linear-gradient(
        $bg-color 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), $bg-color 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0
        100%;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  }

  @if $direction == "horizontal" {
    background:
      /* Shadow covers */ linear-gradient(
        to right,
        $bg-color 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(to right, rgba(255, 255, 255, 0), $bg-color 70%),
      /* Shadows */
        radial-gradient(
          farthest-side at 0 50%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          farthest-side at 100% 50%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0
        100%;

    background-position: left center, right center, left center, right center;
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  }
  background-color: $bg-color;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  /* enhances overflow Scrolling on ios */
  -webkit-overflow-scrolling: touch;
}
