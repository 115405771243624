@use "core/src/stylesheets/base/variables" as var;
@import "core/src/stylesheets/utils/size";

.cdk-overlay-pane {
  &.dialog-is-sm,
  &.dialog-is-default,
  &.dialog-is-lg {
    .mat-mdc-dialog-title {
      position: relative;

      &::before {
        display: none;
      }

      .mat-dialog-title-inner {
        &.hide-close-btn {
          $inner-space-x-sm: 100%;
          @include size($inner-space-x-sm, 100%);
        }

        jbd-ui-icon-button {
          position: absolute;
        }
      }
    }
  }

  &.dialog-is-sm {
    .mat-mdc-dialog-title {
      height: var.$dialog-title-height-sm;

      .mat-dialog-title-inner {
        $inner-space-x-sm: calc(
          100% - #{var.$icon-btn-size-sm} - #{var.$dialog-close-btn-pos-right-sm}
        );
        @include size($inner-space-x-sm, 100%);

        jbd-ui-icon-button {
          top: var.$dialog-close-btn-pos-top-sm;
          right: var.$dialog-close-btn-pos-right-sm;
        }
      }
    }
  }

  &.dialog-is-default,
  &.dialog-is-lg {
    .mat-mdc-dialog-title {
      height: var.$dialog-title-height;

      .mat-dialog-title-inner {
        $inner-space-x: calc(
          100% - #{var.$icon-btn-size} - #{var.$dialog-close-btn-pos-right}
        );
        @include size($inner-space-x, 100%);

        jbd-ui-icon-button {
          top: var.$dialog-close-btn-pos-top;
          right: var.$dialog-close-btn-pos-right;
        }
      }
    }
  }
}
