@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/utils/size" as *;

.form-group {
  .mat-form-field-stepper {
    position: relative;
    padding: 2px 4px;
    border-color: map-get(color.$monochrome, 500);
    transition: border-color 0.15s ease-out;

    &:focus-within {
      border-color: map-get(color.$monochrome, 600);
    }

    &-inline-label {
      color: map-get(color.$monochrome, 900);
    }

    input.form-control {
      position: absolute;
      inset: 0;
      z-index: -1;
      opacity: 0;
    }
  }
}
