input[type="number"] {
  -moz-appearance: textfield;

  // remove number input spinners
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
