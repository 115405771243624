@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/base/colors" as color;
@use "variables" as *;

@mixin datepicker-highlight-state {
  font-weight: var.$font-weight-bold;
  background-color: map-get(color.$monochrome, 200);
}

.cdk-overlay-pane.mat-datepicker-popup {
  .mat-datepicker-content {
    margin-block: 8px;

    .mat-form-field-weekpicker-datepicker-widget {
      width: $form-field-weekpicker-limited-width - 2 * $form-field-border-width !important;
    }
  }
}

.cdk-overlay-pane.mat-datepicker-popup,
.cdk-overlay-pane.mat-datepicker-dialog {
  .mat-datepicker-content {
    border: $form-field-border-width solid $form-field-border-color--focused;
    box-shadow: none;

    .mat-form-field-weekpicker-datepicker-widget {
      // header controls
      .mat-calendar-period-button {
        color: map-get(color.$monochrome, 900);
        font-weight: var.$font-weight-bold;
      }

      // body
      .mat-calendar-table-header {
        th {
          font-weight: var.$font-weight-bold;
        }

        .mat-calendar-table-header-divider::after {
          height: $form-field-border-width;
          background-color: map-get(color.$monochrome, 300);
        }
      }

      .mat-calendar-body {
        .mat-calendar-body-label {
          font-weight: var.$font-weight-bold;
          color: map-get(color.$monochrome, 600);
        }

        .mat-calendar-body-cell-content {
          border-width: $form-field-border-width;

          &.mat-calendar-body-today:not(.mat-calendar-body-selected) {
            border-color: transparent;
          }

          &.mat-calendar-body-today.mat-calendar-body-selected {
            box-shadow: none;
          }
        }

        td button.mat-calendar-body-cell {
          font-weight: var.$font-weight-semibold;

          &:not(.mat-calendar-body-disabled):hover {
            .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
              @include datepicker-highlight-state;
            }
          }
        }
      }
    }

    // use picker with keyboard - close button beneath picker
    .mat-datepicker-close-button {
      box-shadow: none;
      font-weight: var.$font-weight-bold;
    }
  }
}

// use picker with keyboard
.cdk-keyboard-focused,
.cdk-program-focused {
  .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    @include datepicker-highlight-state;
  }
}
