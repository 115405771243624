@use "ui/src/stylesheets/partials/form/variables" as form_var;
@use "ui/src/stylesheets/partials/form/form-validation" as form_validation;

form[ngxstripecardgroup] {
  .stripe-input {
    .StripeElement {
      padding: form_var.$form-field-padding-x form_var.$form-field-padding-y;
      color: form_var.$form-field-color;
      border-width: form_var.$form-field-border-width;
      border-style: solid;
      border-color: form_var.$form-field-border-color;
      border-radius: form_var.$form-field-border-radius;
      background-color: form_var.$form-field-background-color;
      transition: border-color 0.15s ease-out;

      &.StripeElement--focus {
        border-color: form_var.$form-field-border-color--focused;
      }

      &.StripeElement--invalid {
        color: form_validation.$form-field-color--invalid;
        background-color: form_validation.$form-field-background-color--invalid;
        border-color: form_validation.$form-field-border-color--invalid;
      }
    }

    &.is-disabled .StripeElement {
      background-color: form_validation.$form-field-background-color--invalid;
      border-color: form_validation.$form-field-border-color--invalid;
    }

    &.is-invalid label {
      color: form_validation.$form-field-label-color--invalid;
    }
  }
}
