form,
.jbd-form {
  transition: opacity 0.25s ease-out;
  opacity: 1;

  &.is-disabled {
    opacity: 0.4;
    transition: opacity 0.25s ease-out;
    cursor: wait;

    * {
      pointer-events: none;
    }
  }
}
