@use "@angular/material/core/theming/inspection" as inspection;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/framework/modules" as module;

jbd-ui-stepper-nav {
  & > nav.stepper-desktop-nav > .stepper-nav-button {
    &.is-active {
      color: inspection.get-theme-color(
        module.$light-theme,
        primary,
        default
      ) !important;
    }

    .step-circle-wrapper > .step-circle.is-small {
      &:not(.is-valid):not(.was-skipped) {
        background-color: inspection.get-theme-color(
          module.$light-theme,
          primary,
          200
        );
        color: inspection.get-theme-color(
          module.$light-theme,
          primary,
          default
        );
      }

      &.was-skipped {
        border: var.$border-width-default solid
          inspection.get-theme-color(module.$light-theme, primary, 200);
        background-color: map-get(color.$monochrome, "white");
        color: inspection.get-theme-color(
          module.$light-theme,
          primary,
          default
        );
      }
    }
  }

  & > nav.stepper-mobile-nav {
    .stepper-spinner-wrapper:before {
      border-color: inspection.get-theme-color(
        module.$light-theme,
        primary,
        200
      );
    }

    .stepper-text-value {
      color: inspection.get-theme-color(module.$light-theme, primary, default);
    }
  }
}
