@use "@angular/material" as mat;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/base/colors" as colors;
@use "core/src/stylesheets/utils/size" as *;

//prefix
mat-form-field.mat-mdc-form-field.mat-mdc-form-field-has-icon-prefix
  .mat-mdc-form-field-icon-prefix {
  padding: 0;

  mat-icon.mat-icon {
    display: inline-flex;
    align-items: center;
  }
}

// suffix
.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-icon-suffix {
  @include mat.icon-button-density(-4);
  padding-inline-start: 8px !important;
  margin-inline-end: -4px;

  .mdc-icon-button.mat-mdc-icon-button {
    border-radius: var.$border-radius-xs;
    --mdc-icon-button-icon-size: 24px;

    .mat-mdc-button-persistent-ripple {
      border-radius: inherit;
    }

    mat-icon.mat-icon svg {
      --mdc-icon-button-icon-size: 20px;
      @include size(var(--mdc-icon-button-icon-size) !important);
    }

    mat-spinner.mat-mdc-progress-spinner {
      --mdc-icon-button-icon-size: 20px;
      @include size(var(--mdc-icon-button-icon-size) !important);
      transform: translateX(2px);
    }
  }
}

// suffix styles
.mat-mdc-form-field-icon-suffix .mat-mdc-icon-button {
  color: map-get(colors.$monochrome, 600);
}

// suffix disabled styles
.mat-form-field-disabled {
  .mat-mdc-form-field-text-suffix,
  .mat-mdc-form-field-icon-suffix {
    pointer-events: none;
    opacity: 0.4;
  }
}
