mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline.jbd-form-file-wrapper {
  .mat-mdc-form-field-flex {
    padding: 0;

    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-style: dashed;
      }

      .mdc-notched-outline__leading {
        border-right-style: none;
      }

      .mdc-notched-outline__notch {
        border-left-style: none;
        border-right-style: none;
      }

      .mdc-notched-outline__trailing {
        border-left-style: none;
      }
    }

    .mat-mdc-form-field-infix {
      padding-block: 0;
    }
  }
}
