@use "variables" as *;

mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field {
  padding-inline: $form-field-padding-x;
  line-height: 1;
  background-color: $form-field-background-color;
  transition: background-color 0.1s ease-out;

  .mat-mdc-form-field-flex {
    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $form-field-border-color;
        border-width: $form-field-border-width;
        transition: border-color 0.15s ease-out;
      }
    }

    .mat-mdc-form-field-infix {
      padding-block: $form-field-padding-y;
      min-height: unset;
      width: unset;
    }
  }
}

// focused styles
mat-form-field.mat-mdc-form-field
  .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--focused
  .mat-mdc-form-field-flex
  .mdc-notched-outline {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $form-field-border-color--focused;
  }
}

// disabled styles
mat-form-field.mat-mdc-form-field
  .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--disabled
  .mat-mdc-form-field-flex {
  .mdc-notched-outline::after {
    position: absolute;
    inset: 0;
    content: "";
    background-color: $form-field-background-color--disabled;
  }

  .mat-mdc-form-field-infix input[disabled] {
    color: $form-field-color--disabled;
  }
}

// placeholder styles
mat-form-field.mat-mdc-form-field
  .mat-mdc-text-field-wrapper.mdc-text-field
  .mat-mdc-form-field-flex
  .mat-mdc-form-field-infix {
  textarea::placeholder,
  input::placeholder {
    font-weight: $form-field-placeholder-font-weight;
    color: $form-field-placeholder-color;
    white-space: normal;
  }

  textarea[disabled]::placeholder,
  input[disabled]::placeholder {
    color: $form-field-placeholder-color--disabled;
  }
}

// make it possible to limit width from outside
mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field {
  max-width: var(--input-max-width, unset);
}

// make it possible to set alternative text-align
mat-form-field.mat-mdc-form-field
  .mat-mdc-text-field-wrapper.mdc-text-field
  .mat-mdc-form-field-flex
  input,
textarea {
  text-align: var(--input-text-align, left);
}
