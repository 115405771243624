@use "@angular/material" as mat;
@use "core/src/stylesheets/utils/size" as *;
@use "variables" as *;

mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker {
  position: relative;

  &.has-limited-width {
    width: $form-field-weekpicker-limited-width;
  }

  &-displayed-date {
    color: $form-field-color;
  }

  input.form-control {
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    padding-inline-start: 4px;

    .mat-mdc-form-field-infix {
      padding-block: 4px !important;
    }
  }

  .mat-mdc-form-field-icon-suffix jbd-ui-icon-button {
    @include mat.icon-button-density(-4);

    mat-icon.mat-icon svg {
      --mdc-icon-button-icon-size: 16px;
      @include size(var(--mdc-icon-button-icon-size) !important, auto);
    }
  }
}

// focused styles
.form-group.mat-datepicker-is-open
  mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker,
mat-form-field.mat-mdc-form-field.mat-form-field-weekpicker:focus-within {
  .mat-mdc-text-field-wrapper.mdc-text-field .mat-mdc-form-field-flex {
    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $form-field-border-color--focused;
      }
    }
  }
}
