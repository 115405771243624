@use "core/src/stylesheets/base/colors" as color;

$form-field-label-color--invalid: map-get(color.$danger, 600);
$form-field-color--invalid: map-get(color.$danger, 600);
$form-field-border-color--invalid: map-get(color.$danger, 600);
$form-field-background-color--invalid: map-get(color.$danger, 50);
$form-field-suffix-icon-color--invalid: map-get(color.$danger, 400);

// label
.form-group.is-invalid {
  mat-label,
  label.mat-label {
    color: $form-field-label-color--invalid;
  }
}

// input, textarea, select, autocomplete
.form-group.is-invalid
  mat-form-field.mat-mdc-form-field
  .mat-mdc-text-field-wrapper.mdc-text-field {
  background-color: $form-field-background-color--invalid;

  .mat-mdc-form-field-flex .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $form-field-border-color--invalid;
    }
  }
}

// form field suffix icon
.form-group.is-invalid
  mat-form-field.mat-mdc-form-field
  .mat-mdc-form-field-icon-suffix
  .mat-mdc-icon-button {
  color: $form-field-suffix-icon-color--invalid;
}

// mat-form-field-checkbox
.form-group.is-invalid {
  mat-checkbox.mat-mdc-checkbox {
    background-color: $form-field-background-color--invalid;

    .mdc-checkbox {
      --mdc-checkbox-unselected-icon-color: #{$form-field-border-color--invalid};

      &:hover
        .mdc-checkbox__native-control:enabled:not(:checked):not(
          :indeterminate
        ):not([data-indeterminate="true"])
        ~ .mdc-checkbox__background {
        background-color: map-get(color.$monochrome, "white");
      }
    }

    &.mat-mdc-checkbox-checked {
      --mdc-checkbox-selected-icon-color: #{$form-field-border-color--invalid};
      --mdc-checkbox-selected-focus-icon-color: var(
        --mdc-checkbox-selected-icon-color
      );
      --mdc-checkbox-selected-hover-icon-color: var(
        --mdc-checkbox-selected-icon-color
      );
      --mdc-checkbox-selected-pressed-icon-color: var(
        --mdc-checkbox-selected-icon-color
      );
    }
  }
}

// mat-form-field-file
.form-group.is-invalid {
  .jbd-form-file-wrapper {
    ngx-dropzone.jbd-form-file-dropzone ngx-dropzone-label > span {
      color: $form-field-color--invalid;
    }
  }
}
