$font-family: "Open Sans", system-ui, -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

// font-weight
// read more about open sans font-weight: https://fonts.google.com/specimen/Open+Sans
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

// line-height
$line-height-base: 1.5;

$line-height-h1: 1;
$line-height-h2: 1.15;
$line-height-h3: 1.2;
$line-height-h4: 1.25;
$line-height-h5: 1.25;
$line-height-h6: $line-height-base;
$line-height-body-1: $line-height-base;
$line-height-body-2: $line-height-base;
$line-height-caption: $line-height-base;

// font-size
// clamp values calculated via https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/#for-those-who-dont-mind-that-edge-case
$font-size-base: 1rem; // ~16px
$font-size-sm: 0.875rem; // ~14px
$font-size-xs: 0.75rem; // ~12px
$font-size-xxs: 0.625rem; // ~10px

$font-size-h1: clamp(
  1.5rem,
  1.25rem + 1.25vw,
  2rem
); // ~32px desktop, ~24px mobile
$font-size-h2: clamp(
  1.25rem,
  1.125rem + 0.625vw,
  1.5rem
); // ~24px desktop, ~20px mobile
$font-size-h3: clamp(
  1rem,
  0.875rem + 0.625vw,
  1.25rem
); // ~20px desktop, 16px mobile
$font-size-h4: clamp(
  0.875rem,
  0.8125rem + 0.3125vw,
  1rem
); // ~16px desktop, 14px mobile
$font-size-h5: $font-size-sm;
$font-size-h6: $font-size-xs;
$font-size-body-1: $font-size-base;
$font-size-body-2: $font-size-base;
$font-size-caption: $font-size-xs;

// letter-spacing
$letter-spacing-h1: -0.0125em;
$letter-spacing-h2: -0.01125em;
$letter-spacing-h3: -0.01em;
$letter-spacing-h4: -0.0075em;

// calculated from xd via https://codepen.io/raunaqpatel/full/bGpwBvo
$letter-spacing-h6: 0.48px;

// border-radius
$border-radius-lg: 16px;
$border-radius: 8px;
$border-radius-sm: 4px;
$border-radius-xs: 2px;

$border-width-default: 2px;

// layout
$sidenav-width: 250px;
$sidenav-user-actions-trigger-text-max-width: 140px;
$sidenav-user-actions-dropdown-padding-x: 16px;
$sidenav-user-actions-dropdown-width: calc(
  #{$sidenav-width} - 2 * #{$sidenav-user-actions-dropdown-padding-x}
);
$mat-toolbar-height: 56px;

// impersonate
$admin-impersonate-alert-width: clamp(240px, 320px, 50vw);
$admin-impersonate-alert-z-index: 2;

// cards
$card-padding-y: 24px;
$card-padding-x: 20px;

$preview-card-min-height: 150px;
$preview-card-thumbnail-width: 128px;
$preview-card-thumbnail-border-radius: 1px;
$preview-card-thumbnail-xs-height: 250px;
$preview-card-thumbnail-xs-border-radius: 2px;
$preview-card-thumbnail-xs-width: calc(
  100% - #{$preview-card-thumbnail-xs-border-radius * 2}
);
$preview-card-thumbnail-placeholder-icon-size: 48px;

// divider
$divider-margin-y: 24px;
$divider-height: 2px;
$divider-width: 2px;
$divider-radius: 2px;

// buttons
$btn-min-width: 150px;
$btn-min-width-sm: 120px;
$btn-min-width-xs: 90px;
$btn-min-height: 44px;
$btn-min-height-sm: 36px;
$btn-min-height-xs: 24px;
$btn-padding-x: 16px;
$btn-padding-x-sm: 12px;
$btn-padding-x-xs: 8px;
$btn-font-size: $font-size-sm;
$btn-font-weight: $font-weight-bold;
$btn-inline-icon-size: $font-size-base;
$btn-border-radius: $border-radius-sm;

$btn-secondary-border-width: $border-width-default;

$icon-btn-size-lg: 48px;
$icon-btn-size: 40px;
$icon-btn-size-sm: 36px;
$icon-btn-size-xs: 24px;
$icon-btn-icon-size-lg: $font-size-base * 1.33;
$icon-btn-icon-size: $font-size-base * 1.25;
$icon-btn-icon-size-sm: $font-size-base;
$icon-btn-icon-size-xs: $font-size-xs;

// dialog
$dialog-container-width: 500px;
$dialog-container-width-sm: 300px;
$dialog-container-width-lg: 960px;
$dialog-container-padding-y: 80px;
$dialog-container-padding-y-sm: 50px;
$dialog-title-height: 88px;
$dialog-title-height-sm: 56px;
$dialog-close-btn-margin-x: 10px;
$dialog-close-btn-margin-x-sm: 16px;
$dialog-close-btn-pos-right: 16px;
$dialog-close-btn-pos-right-sm: 10px;
$dialog-close-btn-pos-top: 24px;
$dialog-close-btn-pos-top-sm: 10px;
$dialog-content-max-height-landscape: 100vh;
$dialog-content-max-height-portrait: 100vh;
$dialog-content-padding-x: 16px;
$dialog-content-padding-x-sm: 16px;
$dialog-content-padding-y: 20px;
$dialog-content-padding-y-sm: 16px;
$dialog-footer-height: 86px;

// alert
$alert-border-width: 1px;
$alert-close-btn-pos-right: 4px;
$alert-close-btn-pos-top: 4px;
$alert-close-btn-margin-x: 8px;
$alert-inner-padding: 12px;

// state
$state-icon: 36px;
$state-icon-sm: 32px;
$state-icon-xl: 64px;

// contact
$contact-min-width: 270px;
$contact-icon-circle-size: 24px;
$contact-icon-size: $font-size-xs;

// radio-buttons
$radio-button-circle-size: 16px;

// tabs
$tabs-indicator-bar-height: 3px;

// stepper
$stepper-circle-border-width: 4px;
$stepper-circle-size: 24px;
$stepper-mobile-circle-border-width: 6px;
$stepper-mobile-circle-size: 54px;
$stepper-container-desktop-min-width: $contact-min-width;

// list
$simple-list-item-custom-marker-gap: 8px;
$simple-list-item-margin-bottom: 4px;

// no-content-template
$no-content-icon-size: 48px;

// chips
$chip-inner-padding: 3px 8px;
$chip-font-size: $font-size-xxs;
$chip-font-weight: $font-weight-semibold;
$chip-border-radius: $border-radius;
$chip-letter-spacing: 0.25px;

// table
$table-border-width: 2px;
$table-column-padding-outer-x: 16px;
$table-column-padding-inner-x: 4px;
$table-column-padding-y: 14px;
$table-column-actions-padding-y: 4px;
$table-thead-height: 48px;
$table-thead-font-size: $font-size-sm;
$table-thead-font-weight: $font-weight-semibold;
$table-tbody-font-size: $font-size-sm;
$table-tbody-font-weight: $font-weight-regular;

// paginator
$paginator-label-font-weight: $font-weight-semibold;
$paginator-label-font-size: $font-size-base;
$paginator-label-margin-x: 12px;

// menu
$mat-menu-min-height: 48px;

// nav-list
$nav-list-height-dense: 36px;
$nav-list-height: 48px;
$nav-list-item-padding-left: 8px;
$nav-list-nested-item-padding-left: 16px;

// stepper
$stepper-height: 43px;

// inline-badge
$inline-badge-width: 20px;
$inline-badge-height: 16px;
