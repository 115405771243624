@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/utils/size" as *;

.add-qualification-btn,
.clear-search-btn {
  mat-icon svg {
    transform: translateY(-4px) !important;
    --mdc-icon-button-icon-size: 16px !important;
  }
}

.has-map-bg {
  background-image: url("/assets/images/map/button-bg.svg");
  background-size: cover;
}

.checkout-edit-btn {
  --btn-min-width-xs: 0;

  jbd-ui-button-inner span {
    font-size: var.$font-size-xs;
    font-weight: var.$font-weight-semibold;
    text-decoration: underline;
  }
}

.filter-toggle-icon-btn {
  @include size(var.$icon-btn-size-lg !important);
  font-size: var(--icon-btn-icon-size, #{var.$icon-btn-icon-size-lg});
}

.filter-toggle-labelled-btn {
  .mat-icon.filter-toggle-labelled-btn-icon {
    transform: rotate(0deg);
    transition: transform 0.25s ease;
  }

  &.is-expanded .mat-icon.filter-toggle-labelled-btn-icon {
    transform: rotate(-180deg);
  }
}
