jbd-ui-stepper-nav nav:not(.stepper-mobile-nav) .stepper-nav-button {
  &[disabled] {
    cursor: not-allowed;
    pointer-events: unset;

    &:hover .mat-mdc-button-persistent-ripple {
      opacity: 0;
    }

    & > {
      pointer-events: none;
    }

    span.stepper-nav-button-label,
    .step-circle-wrapper .step-circle.is-small span {
      opacity: 0.6;
    }
  }

  .mdc-button__label {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    z-index: -1;
  }
}
