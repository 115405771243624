.section-toggle-btn {
  .mat-icon {
    transform: rotate(0deg);
    transition: transform 0.25s ease;
  }

  &.is-expanded .mat-icon {
    transform: rotate(-180deg);
  }
}
