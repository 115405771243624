@use "@ngbracket/ngx-layout/core/sass/layout-bp" as mq;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/utils/scroll-hints" as *;

.cdk-overlay-pane {
  .mat-mdc-dialog-container .mat-mdc-dialog-content {
    --mdc-dialog-supporting-text-color: initial;

    margin: 0;
    overscroll-behavior-y: contain;
    overflow-y: auto;
    @include scroll-hints(map-get(color.$monochrome, 100), "vertical");

    @media screen and (orientation: landscape) {
      $max-height: calc(
        #{var.$dialog-content-max-height-landscape} - #{var.$dialog-container-padding-y *
          2} - #{var.$dialog-title-height}
      );

      max-height: calc(#{$max-height} - #{var.$dialog-footer-height});

      &.dialog-has-no-footer-action-row {
        max-height: $max-height;
      }

      @include mq.layout-bp(lt-sm) {
        $max-height-sm: calc(
          #{var.$dialog-content-max-height-landscape} - #{var.$dialog-container-padding-y-sm *
            2} - #{var.$dialog-title-height} - #{var.$dialog-footer-height}
        );

        max-height: calc(#{$max-height-sm} - #{var.$dialog-footer-height});

        &.dialog-has-no-footer-action-row {
          max-height: $max-height-sm;
        }
      }
    }

    @media screen and (orientation: portrait) {
      $max-height: calc(
        #{var.$dialog-content-max-height-portrait} - #{var.$dialog-container-padding-y *
          2} - #{var.$dialog-title-height}
      );

      max-height: calc(#{$max-height} - #{var.$dialog-footer-height});

      &.dialog-has-no-footer-action-row {
        max-height: $max-height;
      }

      @include mq.layout-bp(lt-sm) {
        $max-height-sm: calc(
          #{var.$dialog-content-max-height-portrait} - #{var.$dialog-container-padding-y-sm *
            2} - #{var.$dialog-title-height}
        );

        max-height: calc(#{$max-height-sm} - #{var.$dialog-footer-height});

        &.dialog-has-no-footer-action-row {
          max-height: $max-height-sm;
        }
      }
    }
  }

  &.dialog-is-sm {
    .mat-mdc-dialog-container .mat-mdc-dialog-content {
      padding: var.$dialog-content-padding-y-sm var.$dialog-content-padding-x-sm;
    }
  }

  &.dialog-is-default,
  &.dialog-is-lg {
    .mat-mdc-dialog-container .mat-mdc-dialog-content {
      padding: var.$dialog-content-padding-y var.$dialog-content-padding-x;
    }
  }
}
