@use "../base/colors" as color;
@use "../base/variables" as var;

.border-0 {
  border: none !important;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid !important;
}

.border {
  border-width: var.$border-width-default !important;
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-bottom {
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
}

.border-right {
  border-right-width: 2px !important;
  border-right-style: solid !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius {
  border-radius: var.$border-radius !important;
}

.border-radius-sm {
  border-radius: var.$border-radius-sm !important;
}

.border-radius-lg {
  border-radius: var.$border-radius-lg !important;
}

.border-color-100 {
  border-color: map-get(color.$monochrome, 100) !important;
}

.border-color-200 {
  border-color: map-get(color.$monochrome, 200) !important;
}

.border-color-300 {
  border-color: map-get(color.$monochrome, 300) !important;
}

.border-color-400 {
  border-color: map-get(color.$monochrome, 400) !important;
}

.border-color-500 {
  border-color: map-get(color.$monochrome, 500) !important;
}

.border-color-transparent {
  border-color: transparent !important;
}

.border-color-danger-600 {
  border-color: map-get(color.$danger, 600) !important;
}

.is-circle {
  border-radius: 50% !important;
}
