@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

.enter-payment-details-radio-group mat-radio-button.mat-mdc-radio-button {
  background-color: map-get(color.$monochrome, "white");
  border: var.$border-width-default solid map-get(color.$monochrome, 200);
  padding: 4px 16px 16px 4px !important;
}

.roles-radio-group mat-radio-button.mat-mdc-radio-button {
  background-color: map-get(color.$monochrome, "white");
  border: var.$border-width-default solid map-get(color.$monochrome, 200);
  padding: 8px 8px 8px 4px !important;
}
