// import util globals here

html {
  min-height: 100%;
  display: flex;
}

body {
  min-height: 100%;
  flex: 1;
  background-color: white;
}
