@use "@ngbracket/ngx-layout/core/sass/layout-bp" as mq;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

.mat-mdc-radio-group {
  // default styling
  mat-radio-button.mat-mdc-radio-button {
    min-width: 100px;
    background-color: map-get(color.$monochrome, 200);
    border-radius: var.$border-radius-sm;

    .mdc-form-field {
      display: flex;
      align-items: flex-start;

      label[for^="mat-radio"] {
        $radio-btn-width: 40px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 8px 8px 8px calc(#{$radio-btn-width} + 4px);
        flex: 1;
        font-size: var.$font-size-sm;
        margin-left: -$radio-btn-width;
      }
    }
  }
}

.mat-mdc-radio-group {
  gap: 4px;

  &.is-stacked {
    flex-direction: column !important;
  }

  &:not(.is-stacked) {
    @include mq.layout-bp(gt-xs) {
      gap: 12px;
    }
  }
}

.mat-mdc-radio-group:not(.inline-label-can-wrap) label {
  white-space: nowrap;
}

// disabled styles
.mdc-radio.mdc-radio--disabled,
.mdc-radio.mdc-radio--disabled + label[for^="mat-radio"] {
  cursor: not-allowed !important;
}
