@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}
