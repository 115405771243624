@use "../base/colors" as color;
@use "../base/variables" as var;

hr {
  margin-block: var.$divider-margin-y;
  border-width: var.$divider-height / 2;
  border-color: map-get(color.$monochrome, 300);
  border-radius: var.$divider-radius;
}

mat-divider.mat-divider.mat-divider-vertical {
  border-right-width: var.$divider-width;
  border-color: map-get(color.$monochrome, 300);
  border-radius: var.$divider-radius;
}
