/* read more: https://material.angular.io/guide/typography#using-typography-styles-in-your-application */

@use "@angular/material" as mat;
@use "../base/variables" as *;

$typography: mat.m2-define-typography-config(
  $font-family: $font-family,
  $headline-5:
    mat.m2-define-typography-level(
      $font-size-h1,
      $line-height-h1,
      $font-weight-bold,
      null,
      $letter-spacing-h1
    ),
  $headline-6:
    mat.m2-define-typography-level(
      $font-size-h2,
      $line-height-h2,
      $font-weight-bold,
      null,
      $letter-spacing-h2
    ),
  $subtitle-1:
    mat.m2-define-typography-level(
      $font-size-h3,
      $line-height-h3,
      $font-weight-bold,
      null,
      $letter-spacing-h3
    ),
  $subtitle-2:
    mat.m2-define-typography-level(
      $font-size-h4,
      $line-height-h4,
      $font-weight-bold,
      null,
      $letter-spacing-h4
    ),
  $body-1:
    mat.m2-define-typography-level(
      $font-size-body-1,
      $line-height-body-1,
      $font-weight-regular,
      null
    ),
  $body-2:
    mat.m2-define-typography-level(
      $font-size-body-2,
      $line-height-body-2,
      $font-weight-regular,
      null
    ),
  $caption:
    mat.m2-define-typography-level(
      $font-size-caption,
      $line-height-caption,
      $font-weight-semibold,
      null
    ),
);

@include mat.typography-hierarchy($typography);
