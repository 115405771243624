// add app related variables here

$map-details-height: 130px;

$add-locations-dialog-height: clamp(250px, 60vh, 568px);

$wysiwyg-editor-height: 260px;
$wysiwyg-tooltip-width: 280px;
$wysiwyg-tooltip-width: 280px;
$wysiwyg-tooltip-edit-icon: "/assets/images/landingpage/pen-solid.svg";
$wysiwyg-tooltip-delete-icon: "/assets/images/landingpage/trash-alt-solid.svg";
