@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/base/colors" as color;

jbd-ui-chip .mat-mdc-chip {
  --mdc-chip-elevated-container-color: #{map-get(color.$monochrome, 300)};
  --mdc-chip-label-text-color: #{map-get(color.$monochrome, 900)};
  --mdc-chip-disabled-label-text-color: var(--mdc-chip-label-text-color);
  --mdc-chip-label-text-size: #{var.$chip-font-size};
  --mdc-chip-label-text-weight: #{var.$chip-font-weight};
  --mdc-chip-container-shape-radius: #{var.$chip-border-radius};
  --mdc-chip-label-text-tracking: #{var.$chip-letter-spacing};
  --mdc-chip-container-height: auto !important;

  line-height: 1;
  min-height: revert;
  padding: var.$chip-inner-padding;

  .mat-mdc-chip-action {
    padding-inline: 0;
  }
}

jbd-ui-chip .mat-mdc-chip {
  &.is-success {
    --mdc-chip-elevated-container-color: #{map-get(color.$success, 200)};
  }

  &.is-warn {
    --mdc-chip-elevated-container-color: #{map-get(color.$warn, 100)};
  }

  &.is-danger {
    --mdc-chip-elevated-container-color: #{map-get(color.$danger, 100)};
  }

  &.is-info {
    --mdc-chip-elevated-container-color: #{map-get(color.$info, 100)};
  }

  &.is-accent {
    --mdc-chip-elevated-container-color: #{map-get(color.$accent, 300)};
  }
}

jbd-ui-chip .mat-mdc-chip {
  &:not(.is-clickable):not(.mdc-evolution-chip--disabled) {
    &:active {
      box-shadow: none;
    }

    &::after {
      background: none;
    }
  }
}
