@use "../base/variables" as var;
@use "../base/colors" as color;

/**
  SHARED STYLES
 */

.jbd-table {
  width: 100%;
}

.jbd-table thead {
  // spacing
  tr.mat-mdc-header-row {
    height: var.$table-thead-height;
  }

  tr.mat-mdc-header-row th.mat-mdc-header-cell {
    padding-inline: var.$table-column-padding-inner-x;

    &:first-of-type {
      padding-inline-start: var.$table-column-padding-outer-x;
    }

    &:last-of-type {
      padding-inline-end: var.$table-column-padding-outer-x;
    }
  }

  // cosmetics
  tr.mat-mdc-header-row th.mat-mdc-header-cell {
    border-bottom-color: map-get(color.$monochrome, 400);
    border-bottom-width: var.$table-border-width;
    color: map-get(color.$monochrome, 700);
    font-size: var.$table-thead-font-size;
    font-weight: var.$table-thead-font-weight;
  }
}

.jbd-table tbody tr.mat-mdc-row {
  vertical-align: top;

  // cosmetics
  td.mat-mdc-cell {
    color: map-get(color.$monochrome, 900);
    font-size: var.$table-tbody-font-size;
    font-weight: var.$table-tbody-font-weight;
  }
}

/**
  SIMPLE TABLE STYLES
 */

.jbd-table:not(.is-expandable) tbody {
  // spacing
  tr.mat-mdc-row td.mat-mdc-cell {
    padding: var.$table-column-padding-y var.$table-column-padding-inner-x;

    &:first-of-type {
      padding-inline-start: var.$table-column-padding-outer-x;
    }

    &:last-of-type {
      padding-inline-end: var.$table-column-padding-outer-x;
    }

    &.mat-column-actions {
      padding-block: var.$table-column-actions-padding-y;
      text-align: right;
    }
  }

  // border
  tr.mat-mdc-row td.mat-mdc-cell {
    border-bottom: var.$table-border-width solid map-get(color.$monochrome, 200);
  }
}

/**
  EXPANDABLE TABLE STYLES
 */

.jbd-table.is-expandable tbody {
  tr.mat-mdc-row {
    // spacing
    &.main-row td.mat-mdc-cell {
      cursor: pointer;
      padding: var.$table-column-padding-y var.$table-column-padding-inner-x;

      &:first-of-type {
        padding-inline-start: var.$table-column-padding-outer-x;
      }

      &:last-of-type {
        padding-inline-end: var.$table-column-padding-outer-x;
      }

      &.mat-column-actions {
        padding-block: var.$table-column-actions-padding-y;
        text-align: right;
      }
    }

    &.detail-row {
      height: 0;

      td.mat-mdc-cell.mat-column-expanded-detail {
        padding: 0;

        .element-detail {
          padding: 0 var.$table-column-padding-outer-x
            var.$table-column-padding-y;
        }
      }
    }

    // border
    &.main-row {
      td.mat-mdc-cell {
        border-bottom: var.$table-border-width solid
          map-get(color.$monochrome, 200);
      }

      &.child-is-expanded td.mat-mdc-cell {
        border-bottom-color: transparent;
      }
    }

    &.detail-row td.mat-mdc-cell.mat-column-expanded-detail {
      border-bottom: none;

      .element-detail {
        border-bottom: var.$table-border-width solid
          map-get(color.$monochrome, 200);
      }
    }

    // cosmetics
    &.main-row:hover,
    &.main-row:active {
      background: map-get(color.$monochrome, 200);
    }
  }
}
