@use "core/src/stylesheets/utils/size" as *;

jbd-ui-preview-file.campaign-preview-image
  .jbd-preview-card-thumbnail
  .jbd-preview-card-thumbnail-inner {
  &:not(.stacked-on-mobile) {
    @include size(170px !important, 235px !important);
  }

  &.stacked-on-mobile {
    height: 100% !important;
    padding-top: 138% !important;
  }
}
